<template>
  <div>
    <section class="section" v-if="description">
      <div class="container">
        <div class="columns">
          <div class="column is-4">
            <VehicleCarousel
              v-bind="{
                vrm,
                cid,
                photos,
                hasAutoCheck,
                precheck: $wait.is('precheck'),
                showCheck: true
              }"
              @check="autocheck"
            />
            <div class="columns is-mobile">
              <div class="column">
                <span class="icon">
                  <i class="fas fa-tachometer" />
                </span>
                <span>Estimated mileage</span>
              </div>
              <div class="column is-narrow">
                <span v-if="mileage">{{ number(mileage) }}</span>
                <span class="has-text-grey-light" v-else>Unavailable</span>
              </div>
            </div>
          </div>
          <div class="column is-8">
            <p class="title is-6 has-text-grey-light">Vehicle overview</p>
            <p class="subtitle is-4 has-text-weight-bold">
              {{ description }}
            </p>
            <div class="columns is-multiline">
              <div class="column is-12">
                <div class="columns is-vcentered is-multiline">
                  <div class="column is-12" v-if="hasAutoCheck">
                    <a
                      class="title is-6 has-text-soft"
                      @click="autocheck()"
                      :disabled="$wait.is('check')"
                    >
                      <span class="icon">
                        <i class="fal fa-car-mechanic" />
                      </span>
                      <span>Check this vehicle's history</span>
                    </a>
                  </div>
                  <div class="column is-12">
                    <a
                      class="title is-6 has-text-soft"
                      @click="checkPermissions('get_a_valuation')"
                    >
                      <span class="icon">
                        <i class="fal fa-hand-holding-usd" />
                      </span>
                      <span>Get a valuation for this vehicle</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="columns" v-if="!isCompanionUser">
          <div class="column is-4">
            <div class="box">
              <div class="content">
                <p class="title is-5 has-text-primary">
                  Get the true market value
                </p>
                <p>
                  Subscribe today to get retail-back valuations that show the
                  true value of this vehicle across the market
                </p>
                <button
                  class="button is-soft is-rounded"
                  @click="toggleUpsell('get_started_now')"
                >
                  Get started now
                </button>
              </div>
            </div>
          </div>
          <div v-if="hasAutoCheck" class="column is-8">
            <div class="box">
              <div class="columns is-vcentered">
                <div class="column">
                  <div class="content">
                    <p class="title is-5 has-text-primary">
                      Make sure this vehicle has the history you think it does
                    </p>
                    <p>
                      1 in 3 vehicles have an untoward history behind them so
                      it's a good idea to perform a vehicle check to make sure
                      your stock is clear from finance, write-offs or theft
                    </p>
                    <button
                      class="button is-soft is-rounded"
                      @click="autocheck(vrm)"
                      :class="{ 'is-loading': checkIsLoading }"
                      :disabled="checkIsLoading"
                    >
                      Get a check now
                    </button>
                  </div>
                </div>
                <div class="column is-narrow">
                  <img
                    src="https://cazana.com/images/homepage/checks-illustration.svg"
                    class="animated pulse infinite"
                    width="200"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column is-4">
            <div class="columns is-multiline">
              <div class="column is-12">
                <VehicleSpec v-bind="{ vrm }" />
              </div>
            </div>
          </div>
          <div class="column is-8">
            <div class="is-fullheight">
              <div
                class="columns is-multiline"
                :class="{ 'pl-5': $mq !== 'mobile' }"
              >
                <div class="column is-12">
                  <div class="columns is-multiline is-mobile">
                    <div class="column">
                      <span class="has-text-dark has-text-weight-semibold">
                        <span class="is-valigned">Vehicle timeline</span>
                      </span>
                    </div>
                    <div class="column is-narrow">
                      <a
                        class="has-text-info has-text-weight-semibold"
                        @click="checkPermissions('view_similar_vehicles')"
                      >
                        <span class="icon has-height-auto">
                          <img
                            height="16"
                            width="16"
                            :src="require(`core/img/icons/percayso-logo.svg`)"
                          />
                        </span>
                        <span class="is-valigned">View similar vehicles</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <VehicleInfoTimeline v-bind="{ vrm, vin }" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section
      class="hero is-fullheight is-pulled-up animated fadeIn"
      v-else-if="error"
    >
      <div class="hero-body">
        <div class="container has-text-centered">
          <p class="title is-4 has-text-danger">
            This vehicle is not available...
          </p>
          <p class="subtitle is-6">
            The plate you are looking for may have moved, or does not exist
          </p>
          <div class="columns is-centered">
            <div class="column is-10 is-11-tablet">
              <DashboardSearch />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="hero is-fullheight is-pulled-up" v-else>
      <div class="hero-body">
        <div class="container">
          <Spinner />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { formatNumber } from 'accounting'
import store from '../stores'
import registerModule from '@/store/services'
import { mapActions, mapGetters } from 'vuex'
import * as autocheck from 'modules/autocheck/services'
import * as companion from 'modules/companion/services'
export default {
  name: 'VehicleProfile',
  data: () => ({ error: false }),
  components: {
    VehicleCarousel: () =>
      import('core/modules/vehicle-carousel/components/VehicleCarousel'),
    VehicleSpec: () => import('../components/VehicleSpec'),
    VehicleInfoTimeline: () =>
      import('modules/vehicle-profile/components/VehicleInfoTimeline'),
    DashboardSearch: () =>
      import('modules/dashboard/components/DashboardSearch'),
    Spinner: () => import('core/components/Spinner')
  },
  computed: {
    ...mapGetters('vpp', [
      'description',
      'mileage',
      'photos',
      'cid',
      'vin'
    ]),
    ...mapGetters('auth', ['isCompanionUser', 'hasAutoCheck']),
    vrm: {
      cache: false,
      get() {
        return this.$route.params.vrm
      }
    }
  },
  watch: {
    async '$route.params'({ vrm }) {
      await this.fetch(vrm)
    }
  },
  created() {
    registerModule('vpp', store)
  },
  async mounted() {
    const { vrm } = this
    await this.fetch(vrm)
  },
  destroyed() {
    this.clear()
  },
  methods: {
    ...mapActions('vpp', ['getProfile', 'reset']),
    async fetch(vrm) {
      try {
        this.clear()
        await this.getProfile(vrm)
      } catch (err) {
        this.error = true
      }
    },
    number(value) {
      return `${formatNumber(value)}`
    },
    clear() {
      this.error = false
      this.reset()
    },
    toggleUpsell(upsell) {
      this.$mxp.track(`vpp_upsell_${upsell}`)
      this.$modal.open('companion/UpsellCompanion')
    },
    checkPermissions(upsell) {
      if (!this.isCompanionUser) {
        this.toggleUpsell(upsell)
      } else {
        this.companionRedirect()
      }
    },
    async companionRedirect() {
      await companion.value({ vrm: this.vrm })
    },
    async autocheck() {
      this.$mxp.track('vpp_carousel_check')
      this.$wait.start('precheck')
      try {
        const data = await autocheck.precheck({
          value: this.vrm,
          type: 'vrm'
        })
        this.$modal.open('autocheck/AutoCheckOptions', {
          vrm: this.vrm,
          vin: data.vin,
          description: autocheck.buildPreDescription(data),
          mileage: data.mileage
        })
      } catch (err) {
        this.$mxp.track('vpp_carousel_precheck_failed')
        const msg = err?.response?.data?.error?.message
        this.$notify(msg || 'Currently unable to perform a check')
      } finally {
        this.$wait.end('precheck')
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.has-height-auto
  height: auto
</style>
