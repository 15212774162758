export default {
  description: (state) => state?.profile?.description,
  mileage: (state) => state?.profile?.estimatedMileage,
  vrm: (state) => state?.profile?.vrm,
  make: (state, { description }) => {
    if (!description) return 'vehicle'
    const arr = description.split(' ')
    return arr.length > 1 ? arr[1] : 'vehicle'
  },
  cid: (state) => state?.profile?.cid,
  vin: (state) => state?.profile?.vin,
  photos: (state) => state?.image
}
