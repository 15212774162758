import { node } from '@/plugins/axios'

export const getProfile = async (vrm) => {
  const path = `/vpp`
  try {
    const { data } = await node.post(path, { vrm })
    return data
  } catch (err) {
    throw err
  }
}

export const getSpec = async (vrm) => {
  try {
    const { data } = await node.get(`/vehicle/${vrm}/specs`)
    return data
  } catch (err) {
    throw err
  }
}

export const getTimeline = async (vin) => {
  const path = `/vehicle/timeline`
  const params = { vin }

  try {
    const { data } = await node.get(path, { params })
    return data
  } catch (err) {
    return false
  }
}
