import { getProfile } from '../services'
import { cz } from '@/plugins/axios'
import state from './state'

export default {
  getProfile: async ({ commit, dispatch }, vrm) => {
    const vehicle = await getProfile(vrm)
    commit('setProfile', { ...vehicle, vrm })
    await dispatch('getImage')
    return vehicle
  },
  reset({ commit }) {
    commit('reset', state())
  },
  async getImage({ commit, getters }) {
    try {
      const { data } = await cz.get(`/image`, {
        params: {
          vrm: getters.vrm
        }
      })
      commit('setImage', data.image)
    } catch {
      commit('setImage', [])
    }
  }
}
